body{
  background-color: #000000;
}


.container_input button:hover{
  cursor: pointer;
}


/*MENU*/

.container_menu{
  text-align: center;
}

.container_menu a{
  color: #f80101;
  text-decoration: none;
}

