.container_movieSearch{
    color: #FFFFFF;
}

.container_search{
    display: flex;
}

.searchInput{
    margin: 10% 0 0 35%;
}

.searchInput input{
    width: 100%;
    outline: none;
    padding: 10px;
    border-radius: 30px;
    text-align: center;
}

.movies_search{
    margin-top: 1%;
    text-align: center;
}