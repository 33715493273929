/* Painel */
.container_input{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 50vw;
    margin-left: 35vw;
    margin-top: -15%;
  }
  
  .container_input input{
    margin-bottom: 10px;
    width: 60%;
    border: 1px solid white;
    padding: 10px;
    outline: none;
  }
  
  .logout a{
    text-decoration: none;
    background-color: #ff0000;
    display: inline-block;
    padding: 10px;
    border-radius: 10%;
    color: #ffffff;
    cursor: pointer;
  }

  .logout button, .container_input button{
    padding: 10px;
    background-color: #ff0000;
    color: #ffffff;
    border-radius: 10px;
    font-weight: bold;
    margin-right: 2%;
    border: 1px solid transparent;
    cursor: pointer;
  }
  
  .bt{
   display: flex;
  }

