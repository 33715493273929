.container_list {
    text-align: center;
    align-items: center;
    margin-top: 20px;
    font-family: Arial, Helvetica, sans-serif;
    color: #FFFFFF;
}


.menu{
  justify-content: center;
  flex-direction: column;
  display: flex;
  margin-bottom: 30px;
}

.menu h1 {
    background-color: #ff0000;
    font-size: 1.2em;
    padding: 10px;
    border-radius: 10px;
}

.menu input{
    outline: none;
    padding: 10px;
    width: 30%;
    border-radius: 30px;
    text-align: center;
}


.container_child span {
    margin-left: 2%;
    background-color: #ff0000;
    padding: 5px;
    border-radius: 50px;
    cursor: pointer;
}

.container_child {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #3a3a3a;
    padding: 10px;
}

.container_child p {
    font-size: 18px;
    font-weight: bold;
}

.container_child input {
    margin-bottom: 10px;
    padding: 5px;
}

.container_list button {
    margin-bottom: 10px;
    padding: 5px 10px;
    background-color: #4285f4;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 2%;
}

#deleteButton{
    background-color: #ff0000;
}

.container_list button:hover {
    background-color: #3367d6;
}

p.message {
    margin-top: 10px;
    font-weight: bold;
}