.Login{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-items: center;
    width: 100vw;
    margin: auto;
}

.Login .form{
    margin: auto;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 10px;
    max-width: 480px;
}

.Login .field label{
    display: block;
}

.Login img{
    width: 150px;
}

.form input{
    padding: 10px;
    margin-top: 5px;
}

.form button{
    margin-top: 3%;
    padding-left: 15px;
    padding-right: 15px;
    background-color: rgba(255, 0, 0, 0.76);
    border: 1px solid #000000;
    transition: .2s ease-in;
}

.form button:hover{
    background-color: rgba(0, 0, 0, 0.76);
    color: #ffffff;
    cursor: pointer;
    transition: .2s ease-in-out;
}


/* Painel */

.message{
    color: #00ff73;
}

.messageErr{
    color: #fa0a0a;
}
